import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layouts/Blog/BlogPost/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Tired to move back and forth between your editor and the tools that you use for testing http requests? There is a solution to this issue.`}</p>
    <p>{`The extension of the day is called `}<a parentName="p" {...{
        "href": "https://marketplace.visualstudio.com/items?itemName=humao.rest-client"
      }}>{`REST Client`}</a>{`.`}</p>
    <p>{`Let’s start and see what it can do.
After installing from the `}<a parentName="p" {...{
        "href": "https://marketplace.visualstudio.com/items?itemName=humao.rest-client"
      }}>{`VSCode marketplace`}</a>{`, you can notice the simplicity of this extension.`}</p>
    <p>{`Create a new tab, paste a raw HTTP request and you are ready to send your first HTTP request from your editor.`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/2048/1*2DZjyEH7AV19sL7B0W396A.png",
        "alt": "Rest"
      }}></img></p>
    <em>Which kind of requests can we send using this extension?</em>
    <p>{`Try to paste in your editor a simple HTTP GET request like so:`}</p>
    <pre><code parentName="pre" {...{}}>{`GET https://www.medium.com/@domenicosolazzo
`}</code></pre>
    <p>{`Click `}<b>{`Command+Option+R`}</b>{` on your Mac (`}<b>{`Ctrl+Alt+R`}</b>{` for Windows), and the response of the server should appear in another pane in your editor.
`}<img parentName="p" {...{
        "src": "https://miro.medium.com/max/1400/1*7gP6wyYie2Sz-VH_Rp9vEA.gif",
        "alt": "Lin"
      }}></img></p>
    <p>{`That was easy. What else can you customize in your request?`}</p>
    <p>{`You can have more control over your request and change anything you want in both the HTTP header and the HTTP message body.`}</p>
    <p>{`In case you want to change the Request Headers, please provide headers with the standard field-value format. One line per header.`}</p>
    <h2>{`REQUEST HEADERS`}</h2>
    <p>{`You can see some example of headers that you add to your request:`}</p>
    <pre><code parentName="pre" {...{}}>{`User-Agent: rest-client
Accept-Language: en-GB,en-US;q=0.8,en;q=0.6,zh-CN;q=0.4
Content-Type: application/json
`}</code></pre>
    <h2>{`REQUEST BODY`}</h2>
    <p>{`If you want to provide the request body, please add a blank line after the request headers.`}</p>
    <p>{`All the content after will be treated as a Request Body.`}</p>
    <p>{`The body could be a XML or JSON snippet, plaintext or even a local file in your machine.`}</p>
    <p>{`Let’s see some more request that you can copy-paste in VSCode.
In the first example, we will send an XML snippet.`}</p>
    <pre><code parentName="pre" {...{}}>{`POST https://www.example.com/sitemap HTTP/1.1
Content-Type: application/xml
Authorization: token <add your authorization token here>

<site>
    <name>Domenico Solazzo</name>
    <url>https://www.domenicosolazzo.com</city>
</site>
`}</code></pre>
    <p>{`In case you want to send a local file, start with < and then the file path (absolute or relative)…you can also do that!`}</p>
    <pre><code parentName="pre" {...{}}>{`POST https://www.example.com/profile HTTP/1.1
Content-Type: application/json
Authorization: token <add your authorization token here>

< /users/domenicosolazzo/profile.json
`}</code></pre>
    <p>{`Well, you got the point. Form data, JSON data, XML data, Plaintext, from local files or not..you decide!`}</p>
    <p>{`Pretty powerful.`}</p>
    <h2>{`CURL`}</h2>
    <p>{`That’s all? No, there are much more features that I will not describe here but…`}</p>
    <p>{`I want to talk about a cool feature of REST Client: cURL.
Well, cURL is not a feature of this REST Client but it can be used inside this extension.`}</p>
    <p>{`I used cURL quite a lot in my daily life as programmer and what if you have a cURL request already in your terminal and you want to run it in your editor?`}</p>
    <p>{`Yes, correct! You can also do that.`}</p>
    <p>{`You can also do the opposite. If you have already a raw HTTP request in your editor, you can copy the request as cURL.`}</p>
    <p>{`Just press F1 and then selecting/typing Rest Client: Copy Request As cURL or simply right-click in the editor, and select Copy Request As cURL.`}</p>
    <h2>{`CODE SNIPPETS`}</h2>
    <p>{`Last, but not least feature is the ability to create code snippets for your favorite programming language, such as Go, JS, Node, C#, etc…`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/800/1*tumyxA8bWJBC1_uFPENjww.jpeg",
        "alt": "Image"
      }}></img></p>
    <pre><code parentName="pre" {...{}}>{`// NodeJS Example for the request: GET http://www.domenicosolazzo
var request = require("request");

var options = { method: 'GET', url: 'https://www.domenicosolazzo.com/' };

request(options, function (error, response, body) {
  if (error) throw new Error(error);

  console.log(body);
});
`}</code></pre>
    <p>{`And you can do much more: check all the features in the official marketplace page: `}<a parentName="p" {...{
        "href": "https://marketplace.visualstudio.com/items?itemName=humao.rest-client"
      }}>{`Rest Client`}</a></p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/11014/0*xbgxeSlCL16fENzz",
        "alt": "Image"
      }}></img></p>
    <p>{`This is a great extension if you want to test the your http request in your editor without switching to Postman or similar tools.`}</p>
    <p>{`I love this extension and I would really recommend it if you are a developer.`}</p>
    <p>{`Your choice. I much prefer in this way!`}</p>
    <p>{`Well, that’s all for today!`}</p>
    <p>{`What is your favourite Editor extension and why?`}</p>
    <h4>{`FOLLOW ME`}</h4>
    <p>{`Do you know that I have a YouTube channel? `}<a parentName="p" {...{
        "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
      }}>{`Subscribe!`}</a></p>
    <p>{`Where can you find me?`}</p>
    <ul>
      <li parentName="ul">{`Youtube: `}<a parentName="li" {...{
          "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
        }}>{`Domenico Solazzo's Channel`}</a></li>
      <li parentName="ul">{`Instagram: `}<a parentName="li" {...{
          "href": "https://www.instagram.com/domenicosolazzo/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Linkedin: `}<a parentName="li" {...{
          "href": "https://www.linkedin.com/in/solazzo/"
        }}>{`solazzo`}</a></li>
      <li parentName="ul">{`Medium: `}<a parentName="li" {...{
          "href": "https://medium.com/@domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Facebook: `}<a parentName="li" {...{
          "href": "https://www.facebook.com/domenicosolazzo.labs/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Snapchat: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Github: `}<a parentName="li" {...{
          "href": "https://github.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Website: `}<a parentName="li" {...{
          "href": "https://www.domenicosolazzo.com"
        }}>{`https://www.domenicosolazzo.com`}</a></li>
      <li parentName="ul">{`Hashnode: `}<a parentName="li" {...{
          "href": "https://hashnode.com/@domenicosolazzo"
        }}>{`https://hashnode.com/@domenicosolazzo`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      